const EventsActionsTypes = {
    FETCH_EVENTS_START : 'FETCH_EVENTS_START',
    FETCH_EVENTS_SUCCESS : 'FETCH_EVENTS_SUCCESS',
    FETCH_EVENTS_FAILURE : 'FETCH_EVENTS_FAILURE',
    DELETE_EVENT_START : 'DELETE_EVENT_START',
    DELETE_EVENT_SUCCESS : 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAILURE : 'DELETE_EVENT_FAILURE',
    ADD_EVENT_START : 'ADD_EVENT_START',
    ADD_EVENT_SUCCESS : 'ADD_EVENT_SUCCESS',
    ADD_EVENT_FAILURE : 'ADD_EVENT_FAILURE',
    GET_EVENT_START : 'GET_EVENT_START',
    GET_EVENT_SUCCESS : 'GET_EVENT_SUCCESS',
    GET_EVENT_FAILURE : 'GET_EVENT_FAILURE',
    UPDATE_EVENT_START : 'UPDATE_EVENT_START',
    UPDATE_EVENT_SUCCESS : 'UPDATE_EVENT_SUCCESS',
    UPDATE_EVENT_FAILURE : 'UPDATE_EVENT_FAILURE',
    CLEAR_VAR_EV : 'CLEAR_VAR_EV',
};

export default EventsActionsTypes;
