const MarksActionsTypes = {
    FETCH_MARKS_START : 'FETCH_MARKS_START',
    FETCH_MARKS_SUCCESS : 'FETCH_MARKS_SUCCESS',
    FETCH_MARKS_FAILURE : 'FETCH_MARKS_FAILURE',
    DELETE_MARK_START : 'DELETE_MARK_START',
    DELETE_MARK_SUCCESS : 'DELETE_MARK_SUCCESS',
    DELETE_MARK_FAILURE : 'DELETE_MARK_FAILURE',
    ADD_MARK_START : 'ADD_MARK_START',
    ADD_MARK_SUCCESS : 'ADD_MARK_SUCCESS',
    ADD_MARK_FAILURE : 'ADD_MARK_FAILURE',
    GET_MARK_START : 'GET_MARK_START',
    GET_MARK_SUCCESS : 'GET_MARK_SUCCESS',
    GET_MARK_FAILURE : 'GET_MARK_FAILURE',
    UPDATE_MARK_START : 'UPDATE_MARK_START',
    UPDATE_MARK_SUCCESS : 'UPDATE_MARK_SUCCESS',
    UPDATE_MARK_FAILURE : 'UPDATE_MARK_FAILURE',
    CLEAR_VAR_M : 'CLEAR_VAR_M',
};

export default MarksActionsTypes;
